import {
  installRegisteredApps,
  MA_APP_IDS,
  maybeInstallMembersArea,
  withMembersArea,
} from '@wix/members-area-integration-kit'
import type {EditorPlatformApp, EditorSDK, TPAComponentType} from '@wix/platform-editor-sdk'
import {ExperimentsBag} from '@wix/wix-experiments'
import {EVENTS_SECTION_ID, GROUPS_APP_DEF_ID, SCHEDULE_SECTION_ID} from '@wix/wix-events-commons-statics'
import {EditorReadyFn} from '@wix/yoshi-flow-editor'
import {getAppManifestFactory} from './editor-script/app-manifest/app-manifest'
import {
  BASE_INSTALL_CONFIG,
  EVENTS_APP_DEF_ID,
  PAID_PLANS_APP_DEF_ID,
  ProgressBarConfig,
  PROMO_INSTALL_CONFIG,
  PROMO_UPDATE_CONFIG,
  LIVE_VIDEO_APP_DEF_ID,
} from './editor-script/constants'
import {onEventFactory} from './editor-script/events'
import {ensurePagesManaged} from './editor-script/pages-panel'
import {getTranslateFunction} from './editor-script/services/translations'
import {parseStaticsUrlFromEditorScriptUrl} from './editor-script/services/url'
import {waitALittle} from './editor-script/services/wait-a-little'
import {createWithApproval, WithApproval} from './editor-script/services/concurrent-editing'
import {FlowApi} from './editor-script/types'

let sdk: EditorSDK
let appToken: string
let t: Function
let adi: boolean
let siteCreation: boolean
let staticsUrl: string
let locale: string
let experiments: ExperimentsBag
let withApproval: WithApproval
let flowApi: FlowApi
let responsive: boolean

interface EventsAppInterface extends Omit<EditorPlatformApp, 'editorReady'> {
  editorReady: EditorReadyFn
}

const EventsApp: EventsAppInterface = {
  editorReady: async (editorSDK, token, options, _flowApi) => {
    experiments = _flowApi.experiments.all()
    const [localeResponse] = await Promise.all([editorSDK.environment.getLocale()])

    locale = localeResponse
    sdk = (editorSDK as unknown) as EditorSDK
    flowApi = _flowApi
    appToken = token
    staticsUrl = parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl)
    t = await getTranslateFunction(staticsUrl, locale)
    siteCreation = options?.origin?.info?.type === 'SITE_CREATION'
    adi = options?.origin?.type === 'ADI'
    responsive = options?.origin?.type === 'RESPONSIVE'
    withApproval = createWithApproval(sdk, token, adi, flowApi)

    await withApproval(async () => {
      await installPage({editorSDK: sdk, token, pageId: EVENTS_SECTION_ID, title: t('pagesPanelEventsDetailsTitle')})

      await installPage({
        editorSDK: sdk,
        token,
        pageId: SCHEDULE_SECTION_ID,
        title: t('pagesPanelEventsScheduleTitle'),
      })

      await ensurePagesManaged(sdk, token, t)
    })

    await setAppAPI()
  },

  handleAction: async (args: any) => {
    const {type, payload} = args
    switch (type) {
      case 'appInstalled':
        switch (payload.appDefinitionId) {
          case EVENTS_APP_DEF_ID:
            if (!adi) {
              const silent = siteCreation
              await withApproval(async () => installMembersArea(false, silent))
            }
            return
          default:
            return
        }
      case 'migrate':
        if (payload?.addPaidPlans) {
          return withApproval(addPaidPlans)
        }
        if (payload?.addSchedulePage) {
          return withApproval(() =>
            installPage({
              editorSDK: sdk,
              pageId: SCHEDULE_SECTION_ID,
              token: appToken,
              title: t('pagesPanelEventsScheduleTitle'),
            }),
          )
        }
        if (payload?.addLiveVideo) {
          return withApproval(addLiveVideo)
        }
        if (payload?.addGroups) {
          return withApproval(addGroups)
        }
        break
      default:
        console.log(type, payload)
        return
    }
  },
  getAppManifest: getAppManifestFactory(
    () => t,
    () => locale,
    () => appToken,
    () => sdk,
    () => experiments,
    () => responsive,
  ),
  onEvent: onEventFactory(
    () => appToken,
    () => experiments,
    () => flowApi,
  ),
}

const setAppAPI = async () => {
  const api: EventsApi = {
    subscribeToRevisionChanged: (): void => null,
    installMembersArea: async () => withApproval(() => installMembersArea(true)),
    installMembersAreaSections: async () => withApproval(installMembersAreaSections),
  }
  await sdk.editor.setAppAPI(appToken, api)
}

const showProgressBar = (config: ProgressBarConfig): Promise<Function> => {
  return new Promise(async resolve => {
    await sdk.editor.openProgressBar(appToken, {
      title: t(config.title),
      totalSteps: 3,
      currentStep: 1,
      stepTitle: t(config.step1),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      currentStep: 2,
      stepTitle: t(config.step2),
    })
    await waitALittle(config.timeBetweenSteps)
    await sdk.editor.updateProgressBar(appToken, {
      currentStep: 3,
      stepTitle: t(config.step3),
    })
    await waitALittle(800)
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, {})
    })
  })
}

const installMembersArea = async (isInstallingFromMembersPromo = false, silent = false) => {
  const progressBarPromise = silent
    ? Promise.resolve(() => {})
    : isInstallingFromMembersPromo
    ? showProgressBar(PROMO_INSTALL_CONFIG)
    : showProgressBar(BASE_INSTALL_CONFIG)

  await maybeInstallMembersArea()
    .then(() => hideProgressBar(progressBarPromise))
    .catch(() => hideProgressBar(progressBarPromise, false))
  // sdk.editor.openProgressBar does not always resolve.
}

const hideProgressBar = (progressBarPromise: Promise<Function>, shouldAwait = true) => {
  progressBarPromise.then(async close => {
    if (shouldAwait) {
      await waitALittle(2000)
    }
    close()
  })
}

const installMembersAreaSections = async () => {
  const progressBarPromise = showProgressBar(PROMO_UPDATE_CONFIG)
  await installRegisteredApps()
  progressBarPromise.then(async close => {
    await waitALittle(1500)
    close()
  })
}

const addPaidPlans = () => {
  return sdk.document.tpa.add.application(appToken, {appDefinitionId: PAID_PLANS_APP_DEF_ID})
}

const addLiveVideo = () => {
  return sdk.document.tpa.add.application(appToken, {appDefinitionId: LIVE_VIDEO_APP_DEF_ID})
}

const addGroups = () => {
  return sdk.document.tpa.add.application(appToken, {appDefinitionId: GROUPS_APP_DEF_ID})
}

const isEventsInstalled = (editorSDK: EditorSDK, token: string) => {
  if (siteCreation) {
    // is platform app installed
    return editorSDK.application.isApplicationInstalled(token, {
      appDefinitionId: EVENTS_APP_DEF_ID,
    })
  } else {
    // is widget installed
    return editorSDK.tpa.isApplicationInstalled(token, {
      appDefinitionId: EVENTS_APP_DEF_ID,
    })
  }
}

const installPage = async ({
  editorSDK,
  token,
  pageId,
  title,
}: {
  editorSDK: EditorSDK
  token: string
  pageId: string
  title?: string
}) => {
  // This is needed because Editor script runs even if Events are uninstalled...
  // Not having this check makes it impossible to uninstall Events

  const eventsInstalled = await isEventsInstalled(editorSDK, token)

  const pageInstalled = await editorSDK.tpa.isAppSectionInstalled(token, {
    sectionId: pageId,
    appDefinitionId: EVENTS_APP_DEF_ID,
  })

  if (eventsInstalled && !pageInstalled) {
    try {
      return await editorSDK.tpa.add.component(token, {
        appDefinitionId: EVENTS_APP_DEF_ID,
        componentType: 'PAGE' as TPAComponentType.Page,
        page: {
          pageId,
          title,
          isHidden: true,
          shouldNavigate: false,
        },
      })
    } catch (e) {
      console.log('Prevented exception from breaking editor script!', e)
      return null
    }
  }
}

const editorApp = withMembersArea(EventsApp, {
  installAutomatically: false,
  membersAreaApps: [MA_APP_IDS.ABOUT, MA_APP_IDS.MY_EVENTS, MA_APP_IDS.MY_WALLET],
})

export const editorReady = editorApp.editorReady
export const handleAction = editorApp.handleAction
export const onEvent = editorApp.onEvent
export const getAppManifest = editorApp.getAppManifest
